'use client';

export default function GlobalError(/* { error }: ErrorPageProps */): JSX.Element {
  return (
    <div className="error">
      <h2>Something went wrong!</h2>
      <a href="/">Go back to Home page</a>
    </div>
  );
}
